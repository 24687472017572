<template>
<v-container>
  <v-card>
    <v-row>
      <v-col cols="9">
          <v-card-title class="mobile-title">Mobile Users</v-card-title>
          <v-card-subtitle class="mobile-subtitle">Number of Logged In Mobile Devices per User (Approx.)</v-card-subtitle>
      </v-col>
      <v-col cols="3">
        <div v-if="!mobile.loading" class="mobile-count-container">
          <div class="mobile-count">Users: {{ numberOfMobileUsers }}</div>
          <div class="mobile-count">Devices: {{ numberOfDevices }}</div>
        </div>
      </v-col>
    </v-row>
    <div class="mobile-content-container">
      <div v-if="mobile.loading">
        <md-progress-spinner class="progress-spinner" md-mode="indeterminate"></md-progress-spinner>
      </div>
      <div v-else>
        <v-row class="mobile-content">
          <v-col cols="12">
            <v-data-table
              class="mobile-users"
              :headers="mobile.headers"
              :items="mobile.data"
              no-data-text="No one logged in using mobile app"
              :items-per-page="10"
              :hide-default-footer="true"
              :page.sync="mobile.page"
              @page-count="mobile.pageCount=$event"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div style="float: right;">
              <v-pagination
                v-model="mobile.page"
                :length="mobile.pageCount"
                color="#473068"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</v-container>
</template>

<script>
import restAdapter from '../../../restAdapter';
export default {
  name: "Analytics",

  data: () => ({
    mobile: {
      loading: false,
      headers: [
        { text: 'Full Name', value: 'user_name', align: 'start', sortable: false },
        { text: 'User Role', value: 'role', align: 'start', sortable: false },
        { text: 'Business Name', value: 'brand_name', align: 'start', sortable: false },
        { text: 'Total Devices (Logged In)', value: 'total_devices', align: 'start', sortable: false, width: '15%' },
      ],
      data: [],
      page: 1,
      pageCount: 1,
    }
  }),

  mounted(){
    this.getMobileUsers();
  },

  methods: {
    async getMobileUsers(){
      this.mobile.loading = true;
      const response = await restAdapter.get('/api/mobile_users');
      this.mobile.data = response.data?.users;
      this.mobile.loading = false;
    }
  },

  computed: {
    numberOfMobileUsers(){
      if(this.mobile.loading) return "Calculating.."
      return this.mobile.data.length;
    },
    numberOfDevices(){
      if(this.mobile.loading) return "Calculating.."
      let total = 0;
      for(let index=0; index < this.mobile.data.length; index++){
        total += parseInt(this.mobile.data[index].total_devices)
      }

      return total;
    }
  }
}
</script>

<style lang="scss" scoped>
  .progress-spinner::v-deep .md-progress-spinner-circle {
    stroke: #492a67 !important;
  }
  .mobile-title {
    color: $primaryDarkest;
    font-weight: 600;
  }
  .mobile-subtitle {
    color: $primaryDark !important;
    float: left;
    text-align: left;
    margin: 0px 0px;
  }
  .mobile-count-container {
    display: flex;
    flex-direction: column;
    float: right;
    padding: 0px 16px 2px;
  }
  .mobile-count {
    float: left;
    border-radius: 8px;
    background-color: $success;
    margin: 2px 0px;
    padding: 2px 10px;
    color: $primaryDark;
  }
  .mobile-content-container {
    padding: 15px;
  }
  .mobile-content {
    padding: 0px 25px;
    justify-content: center;
  }
  .mobile-users::v-deep .text-start {
    color: $primaryDark !important;
  }
  .mobile-users::v-deep .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: 1px solid $primaryDarkest;
  }
</style>
